import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";

const TopBar = () => {
  const handleSuggestionsClick = () => {
    // Lógica para abrir la página de sugerencias o reporte de errores
    window.open("https://forms.gle/guvA8ZRqDwRiDFsz8", "_blank");
  };

  const handleDonationsClick = () => {
    // Lógica para abrir la página de donaciones
    window.open("https://vaki.co/secopProcesos", "_blank");
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Ofertas Publicas Colombia
        </Typography>
        <IconButton color="inherit" onClick={handleSuggestionsClick}>
          <BugReportIcon />
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            Sugerencias o Reporte de errores
          </Typography>
        </IconButton>
        <IconButton color="inherit" onClick={handleDonationsClick}>
          <LocalCafeIcon />
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            Donaciones
          </Typography>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
