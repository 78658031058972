import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import SearchBar from "./components/SearchBar";
import SearchResultsSecop2 from "./components/SearchResults";
import SearchResultsSecop1 from "./components/searchResultSecop1";

import axios from "axios";
import "./App.css";
import { APP_TOKEN } from "./config"; // Importa el token desde config.js
import TopBar from "./components/TopBar";
import { Helmet } from "react-helmet";

function App() {
  const [filters, setFilters] = useState({});
  const [results, setResults] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [secopType, setSecopType] = useState(""); // Para determinar si es SEOP 1 o SEOP 2

  useEffect(() => {
    const fetchDepartamentos = async () => {
      const response = await axios.get(
        "https://www.datos.gov.co/resource/xdk5-pm3f.json",
        {
          params: {
            $limit: 5000,
            $$app_token: APP_TOKEN,
          },
        }
      );
      // Agrupar municipios por departamento
      const departamentosMap = {};
      response.data.forEach((item) => {
        const {
          c_digo_dane_del_departamento,
          departamento,
          c_digo_dane_del_municipio,
          municipio,
        } = item;
        if (!departamentosMap[c_digo_dane_del_departamento]) {
          departamentosMap[c_digo_dane_del_departamento] = {
            codigo: c_digo_dane_del_departamento,
            nombre: departamento,
            municipios: [],
          };
        }
        departamentosMap[c_digo_dane_del_departamento].municipios.push({
          codigo: c_digo_dane_del_municipio,
          nombre: municipio,
        });
      });
      setDepartamentos(Object.values(departamentosMap));
    };

    fetchDepartamentos();
  }, []);

  const buildQueryUrl = (filters) => {
    const soqlClauses = [];
    let baseURL = "";

    if (filters.secop === "secop2") {
      setSecopType("secop2");
      baseURL = "https://www.datos.gov.co/resource/p6dx-8zbt.json";
      if (filters.entidad)
        soqlClauses.push(`entidad like '%25${filters.entidad}%25'`);
      if (filters.departamento)
        soqlClauses.push(`departamento_entidad ='${filters.departamento}'`);
      if (filters.municipio)
        soqlClauses.push(`ciudad_entidad ='${filters.municipio}'`);
      if (filters.objetoContrato)
        soqlClauses.push(
          `descripci_n_del_procedimiento like '%25${filters.objetoContrato}%25'`
        );
      if (filters.estadoContrato)
        soqlClauses.push(`estado_resumen   ='${filters.estadoContrato}'`);
      if (filters.referenciaContrato)
        soqlClauses.push(`id_del_proceso  ='${filters.referenciaContrato}'`);

      if (filters.Modalidad)
        soqlClauses.push(
          `modalidad_de_contratacion like '%25${filters.Modalidad}%25'`
        );
      if (filters.fechaInicio && filters.fechaFin) {
        soqlClauses.push(
          `fecha_de_publicacion_del  between '${filters.fechaInicio}T00:00:00.000' and '${filters.fechaFin}T23:59:59.999'`
        );
      }
      if (filters.unspsc && filters.unspsc.length > 0) {
        soqlClauses.push(
          `codigo_principal_de_categoria ='V1.${filters.unspsc[0].producto}'`
        );
      }
    } else {
      setSecopType("secop1");
      baseURL = "https://www.datos.gov.co/resource/f789-7hwg.json";
      if (filters.entidad)
        soqlClauses.push(`nombre_entidad like '%25${filters.entidad}%25'`);
      if (filters.departamento)
        soqlClauses.push(`departamento_entidad='${filters.departamento}'`);
      if (filters.municipio)
        soqlClauses.push(`municipio_entidad='${filters.municipio}'`);
      if (filters.objetoContrato)
        soqlClauses.push(
          `objeto_a_contratar like '%25${filters.objetoContrato}%25'`
        );
      if (filters.Modalidad)
        soqlClauses.push(
          `modalidad_de_contratacion like '%25${filters.Modalidad}%25'`
        );

      if (filters.fechaInicio && filters.fechaFin) {
        soqlClauses.push(
          `fecha_de_cargue_en_el_secop between '${filters.fechaInicio}T00:00:00.000' and '${filters.fechaFin}T23:59:59.999'`
        );
      }
      if (filters.unspsc && filters.unspsc.length > 0) {
        soqlClauses.push(`id_clase='${filters.unspsc[0].clase}'`);
      }
    }

    const query =
      soqlClauses.length > 0 ? `$where=${soqlClauses.join(" AND ")}` : "";
    return `${baseURL}?${query}&$$app_token=${APP_TOKEN}&$limit=5000`;
  };

  const handleSearch = async () => {
    const queryUrl = buildQueryUrl(filters);
    console.log("Query URL:", queryUrl);
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(queryUrl);
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error al cargar la información.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Licitaciones Públicas en Colombia - Ofertas Públicas Colombia
        </title>
        <meta
          name="description"
          content="Encuentra y consulta procesos de contratación del estado colombiano."
        />
        <meta
          name="keywords"
          content="contratación, procesos, estado, Colombia, SECOP I, SECOP II, SECOP, licitaciones, contratos, entidades, públicas, privadas, gobierno, nacional, departamental, municipal, distrital, transparencia, datos abiertos, gobierno abierto, datos, abierto, gobierno, abierto, datos"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-C2D0JH8NHM"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C2D0JH8NHM');
          `}
        </script>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Licitaciones Públicas en Colombia",
            "description": "Encuentra y consulta las últimas licitaciones públicas y contratos estatales en Colombia.",
            "publisher": {
              "@type": "Organization",
              "name": "Ofertas Públicas"
            }
          }
        `}
        </script>
      </Helmet>
      <TopBar />
      <Container className="App" maxWidth="xl">
        <Typography variant="h4" component="h1" gutterBottom>
          Buscador de Procesos de Contratación
        </Typography>

        <SearchBar
          setFilters={setFilters}
          filters={filters}
          departamentos={departamentos}
          handleSearch={handleSearch}
        />
        <br />
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Snackbar
            open={Boolean(error)}
            autoHideDuration={6000}
            onClose={() => setError(null)}
          >
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        ) : secopType === "secop1" ? (
          <SearchResultsSecop1 results={results} />
        ) : (
          <SearchResultsSecop2 results={results} />
        )}
      </Container>
    </div>
  );
}

export default App;
