import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  IconButton,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeywordSearchModal from "./KeywordSearchModal"; // Asegúrate de importar correctamente la modal
import "./SearchBar.css";

const SearchBar = ({ setFilters, filters, departamentos, handleSearch }) => {
  const [municipios, setMunicipios] = useState([]);
  const [openKeywordModal, setOpenKeywordModal] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (filters.departamento) {
      const selectedDepartamento = departamentos.find(
        (dep) => dep.nombre === filters.departamento
      );
      setMunicipios(
        selectedDepartamento ? selectedDepartamento.municipios : []
      );
    }
  }, [filters.departamento, departamentos]);

  const handleDepartamentoChange = (event) => {
    const departamentoNombre = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      departamento: departamentoNombre,
      municipio: "", // Resetea el municipio cuando cambia el departamento
    }));
  };

  const handleReset = () => {
    setFilters({
      entidad: "",
      departamento: "",
      municipio: "",
      objetoContrato: "",
      fechaInicio: "",
      fechaFin: "",
      secop: "",
      referenciaContrato: "",
      unspsc: [],
    });
    setSelectedKeywords([]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleKeywordAdd = (keyword) => {
    setSelectedKeywords(() => [keyword]);
    setFilters((prevFilters) => ({
      ...prevFilters,
      unspsc: [
        {
          clase: keyword["Código Clase"],
          producto: keyword["Código Producto"],
        },
      ],
    }));
    setOpenSnackbar(true); // Mostrar notificación
  };

  const handleKeywordDelete = (keywordToDelete) => () => {
    setSelectedKeywords((prevKeywords) =>
      prevKeywords.filter((keyword) => keyword !== keywordToDelete)
    );
    setFilters((prevFilters) => ({
      ...prevFilters,
      unspsc: (prevFilters.unspsc || []).filter(
        (code) => code !== keywordToDelete["Código Clase"]
      ),
    }));
  };

  const handleOpenKeywordModal = () => {
    setOpenKeywordModal(true);
  };

  const handleCloseKeywordModal = () => {
    setOpenKeywordModal(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="search-bar">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="estadoContrato-label">
              Estado del Contrato
            </InputLabel>
            <Select
              labelId="estadoContrato-label"
              name="estadoContrato"
              onChange={handleInputChange}
              value={filters.estadoContrato || ""}
            >
              <MenuItem value="Cerrado">Cerrado</MenuItem>
              <MenuItem value="finalizado">Finalizado</MenuItem>
              <MenuItem value="Modificado">Modificado</MenuItem>
              <MenuItem value="En ejecución">En ejecución</MenuItem>
              <MenuItem value="Cancelado">Cancelado</MenuItem>
              <MenuItem value="Borrador">Borrador</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="secop-label">SECOP</InputLabel>
            <Select
              labelId="secop-label"
              name="secop"
              onChange={handleInputChange}
              value={filters.secop || ""}
              required
            >
              <MenuItem value="secop1">SECOP 1</MenuItem>
              <MenuItem value="secop2">SECOP 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Fecha Inicio"
            type="date"
            name="fechaInicio"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
            value={filters.fechaInicio || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Fecha Fin"
            type="date"
            name="fechaFin"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
            value={filters.fechaFin || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Objeto del Contrato"
            name="objetoContrato"
            onChange={handleInputChange}
            value={filters.objetoContrato || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Referencia del Contrato"
            name="referenciaContrato"
            onChange={handleInputChange}
            value={filters.referenciaContrato || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Entidad"
              name="entidad"
              onChange={handleInputChange}
              value={filters.entidad || ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="departamento-label">Departamento</InputLabel>
            <Select
              labelId="departamento-label"
              name="departamento"
              onChange={handleDepartamentoChange}
              value={filters.departamento || ""}
            >
              {departamentos.map((departamento) => (
                <MenuItem key={departamento.codigo} value={departamento.nombre}>
                  {departamento.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="municipio-label">Municipio</InputLabel>
            <Select
              labelId="municipio-label"
              name="municipio"
              onChange={handleInputChange}
              disabled={!municipios.length}
              value={filters.municipio || ""}
            >
              {municipios.map((municipio) => (
                <MenuItem key={municipio.codigo} value={municipio.nombre}>
                  {municipio.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Códigos UNSPSC"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleOpenKeywordModal}>
                    <AddIcon />
                  </IconButton>
                ),
              }}
              value=""
              readOnly
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {selectedKeywords.map((keyword, index) => (
            <Chip
              key={index}
              label={keyword["Nombre Producto"]}
              onDelete={handleKeywordDelete(keyword)}
              style={{ margin: 2 }}
            />
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            fullWidth
          >
            Buscar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleReset}
            fullWidth
          >
            Limpiar
          </Button>
        </Grid>
      </Grid>

      <KeywordSearchModal
        open={openKeywordModal}
        onClose={handleCloseKeywordModal}
        onAddKeyword={handleKeywordAdd}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Código UNSPSC agregado con éxito
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SearchBar;
