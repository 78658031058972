import keywordsData from "../../clasificador_bienes_servicios.json";
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

const KeywordSearchModal = ({ open, onClose, onAddKeyword }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredKeywords, setFilteredKeywords] = useState(keywordsData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSearchQueryChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = keywordsData.filter(
      (keyword) =>
        keyword["Nombre Producto"].toLowerCase().includes(query) ||
        keyword["Código Producto"].toString().includes(query)
    );
    setFilteredKeywords(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Buscar Palabras Clave</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          placeholder="Buscar..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
          margin="dense"
        />
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código Segmento</TableCell>
                <TableCell>Nombre Segmento</TableCell>
                <TableCell>Código Familia</TableCell>
                <TableCell>Nombre Familia</TableCell>
                <TableCell>Código Clase</TableCell>
                <TableCell>Nombre Clase</TableCell>
                <TableCell>Código Producto</TableCell>
                <TableCell>Nombre Producto</TableCell>
                <TableCell>Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredKeywords
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((keyword, index) => (
                  <TableRow key={index}>
                    <TableCell>{keyword["Código Segmento"]}</TableCell>
                    <TableCell>{keyword["Nombre Segmento"]}</TableCell>
                    <TableCell>{keyword["Código Familia"]}</TableCell>
                    <TableCell>{keyword["Nombre Familia"]}</TableCell>
                    <TableCell>{keyword["Código Clase"]}</TableCell>
                    <TableCell>{keyword["Nombre Clase"]}</TableCell>
                    <TableCell>{keyword["Código Producto"]}</TableCell>
                    <TableCell>{keyword["Nombre Producto"]}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onAddKeyword(keyword)}
                      >
                        Agregar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredKeywords.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeywordSearchModal;
