import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
} from "@mui/material";

const SearchResultsSecop1 = ({ results }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (result) => {
    setSelectedResult(result);
  };

  const handleCloseDialog = () => {
    setSelectedResult(null);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(value);
  };

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Nombre de la Entidad</TableCell>
              <TableCell>Departamento</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>Referencia del Contrato</TableCell>
              <TableCell>Estado del Proceso</TableCell>
              <TableCell>Fecha de Inicio</TableCell>
              <TableCell>Fecha de Fin</TableCell>
              <TableCell>Valor del Contrato</TableCell>
              <TableCell>URL del Proceso</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results
              .sort(
                (a, b) =>
                  new Date(b.fecha_de_cargue_en_el_secop) -
                  new Date(a.fecha_de_cargue_en_el_secop)
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((result, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleOpenDialog(result)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{result.nombre_entidad}</TableCell>
                  <TableCell>{result.departamento_entidad}</TableCell>
                  <TableCell>{result.municipio_entidad}</TableCell>
                  <TableCell>{result.numero_de_contrato}</TableCell>
                  <TableCell>{result.estado_del_proceso}</TableCell>
                  <TableCell>
                    {formatDate(result.fecha_ini_ejec_contrato)}
                  </TableCell>
                  <TableCell>
                    {formatDate(result.fecha_fin_ejec_contrato)}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(result.cuantia_contrato)}
                  </TableCell>
                  <TableCell>
                    <Link
                      href={result.ruta_proceso_en_secop_i.url}
                      target="_blank"
                      rel="noopener"
                    >
                      Ver Proceso
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={results.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={Boolean(selectedResult)}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        {selectedResult && (
          <>
            <DialogTitle>Detalles del Proceso</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Nombre de la Entidad:</Typography>
                  <Typography>{selectedResult.nombre_entidad}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">NIT de la Entidad:</Typography>
                  <Typography>{selectedResult.c_digo_de_la_entidad}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Departamento:</Typography>
                  <Typography>{selectedResult.departamento_entidad}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Ciudad:</Typography>
                  <Typography>{selectedResult.municipio_entidad}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Referencia del Contrato:</Typography>
                  <Typography>{selectedResult.numero_de_contrato}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Estado del Proceso:</Typography>
                  <Typography>{selectedResult.estado_del_proceso}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Fecha de Inicio:</Typography>
                  <Typography>
                    {formatDate(selectedResult.fecha_ini_ejec_contrato)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Fecha de Fin:</Typography>
                  <Typography>
                    {formatDate(selectedResult.fecha_fin_ejec_contrato)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Valor del Contrato:</Typography>
                  <Typography>
                    {formatCurrency(selectedResult.cuantia_contrato)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Objeto del Contrato:</Typography>
                  <Typography>
                    {selectedResult.detalle_del_objeto_a_contratar}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">
                    Justificación de la Modalidad:
                  </Typography>
                  <Typography>
                    {selectedResult.causal_de_otras_formas_de}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Nombre del Proveedor:</Typography>
                  <Typography>
                    {selectedResult.nom_razon_social_contratista}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">NIT del Proveedor:</Typography>
                  <Typography>
                    {selectedResult.identificacion_del_contratista}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">
                    Valor Total de Adjudicación:
                  </Typography>
                  <Typography>
                    {formatCurrency(
                      selectedResult.valor_contrato_con_adiciones
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">URL del Proceso:</Typography>
                  <Link
                    href={selectedResult.ruta_proceso_en_secop_i?.url}
                    target="_blank"
                    rel="noopener"
                  >
                    Ver Proceso
                  </Link>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </Paper>
  );
};

export default SearchResultsSecop1;
